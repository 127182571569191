<template>
    <div class="BusinessManagementIndex">

        <div class="head f-c-b">
            <div class="LeftPart f-c">
                <img class="logo" src="@/assets/Community/logo.png" alt="">
                <img class="shiliaologo" src="@/assets/Community/shiliaologo.png" alt="">
            </div>
            <el-breadcrumb class="f-c-e" separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">
                    <img style="width: 16px;height: 14px;" src="@/assets/home.png" alt="">
                    <span> 当前位置： 首页</span>
                </el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/CommunityIndex' }">社区</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/TalentRecruitmentIndex' }">人才招聘</el-breadcrumb-item>
                <el-breadcrumb-item>企业管理</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="main f-cl-c-c">
            <div class="f-c-b w--100">
                <div class="title flex">
                    <div :class="select == 1 ? 'select' : 'Noselect'" @click="select = 1" style="margin-right: 50px;">发布职位管理</div>
                    <div :class="select == 2 ? 'select' : 'Noselect'" @click="select = 2">收到简历</div>
                </div>
                <div v-if="select == 1" class="btn" @click="tomodlify">新增发布职位</div>
            </div>
            
            <div class="list f-cl-c" v-if="select == 1">
                <div v-if="jodList" class="jodList f-cl-c">
                    <div class="jodItem f-cl-c" v-for="(item, index) in jodList" :key="index" @click="tojodDetails(item.postId)">
                    <div class="f-c-b w--100">
                        <div class="position">{{item.position}}</div>
                        <div class="salary">{{item.salary}}</div>
                    </div>
                    <div class="w--100 f-c-b mt-50">
                        <div class="createDate">发布时间：{{ item.createDate }}</div>
                        <div class="col-99" v-if="item.deleteFlag == 'S'">未发布</div>
                        <div style="color: #FFA500;" v-if="item.deleteFlag == 'E'">已过期</div>
                        <div style="color: #0D7CF4;" v-if="item.deleteFlag == 'N'">{{ '于' + item.expirationDate.split(' ')[0] + '到期' }}</div>
                    </div>
                </div>
                </div>
            </div>

            <div class="list f-cl-c" v-if="select == 2">
                <div v-if="resumeList" class="jodList f-cl-c">
                    <div class="jodItem f-cl-c" v-for="(item, index) in resumeList" :key="index" @click="tojodDetails(item.postId)">
                    <div class="f-c-b w--100">
                        <div class="f-c">
                           <div class="position">{{item.position}}</div>
                           <div class="resumeCount ml-50" v-if="item.resumeCount">{{ '查看'+item.resumeCount+'份已投递简历' + ' >' }}</div>
                        </div>
                        
                        <div class="salary">{{item.salary}}</div>
                    </div>
                    <div class="w--100 f-c-b mt-50">
                        <div class="createDate">发布时间：{{ item.createDate }}</div>
                        <div class="col-99" v-if="item.deleteFlag == 'S'">未发布</div>
                        <div style="color: #FFA500;" v-if="item.deleteFlag == 'E'">已过期</div>
                        <div style="color: #0D7CF4;" v-if="item.deleteFlag == 'N'">{{ '于' + item.expirationDate.split(' ')[0] + '到期' }}</div>
                    </div>
                </div>
                </div>
                <Pagination :total="total" :pageNum.sync="currentPage" :limit.sync="size"
                :layout="layout" @pagination="fetchData" />
            </div>
        </div>
    </div>
</template>
  
<script>
  import { PaginationRetention } from '@/mixins/PaginationRetentionMixin';
// import { CommodityTypeClass } from "@/utils/InviteJob.js"
export default {
    name: 'BusinessManagementIndex',
    mixins: [PaginationRetention],

    data() {
        return {
            jodList: [],
            select: 1,
            resumeList: [],
        }
    },
    created() {
        this.forumRecruitEnterpriseArticleList(1, 10);
    },
    methods: {
        getDate(){
            this.forumResumeIntoEnterprisePostList(this.paging, this.pagingSize);
        },
        forumRecruitEnterpriseArticleList(page = this.page, size = this.size){
            let that = this;
            that.$http.forumRecruitEnterpriseArticleList({
                postUserId: parseInt(that.$store.state.userId),
                page,
                size
            }).then(res => {
                if (res.code == 200) {
                    let records = res.data.records;
                    that.jodList = records;
                }
            })
        },
        tojodDetails(id){
            this.$router.push({
                name: 'jodDetails',
                query: {
                    key: this.$UrlEncode.encode(JSON.stringify({
                        id
                    }))
                }
            })
        },
        tomodlify(){
            this.$router.push({
                name: 'ModifyJod',
                query: {
                    key: this.$UrlEncode.encode(JSON.stringify({
                        type: 'add'
                    }))
                }
            })
        },

        forumResumeIntoEnterprisePostList(page = this.page, size = this.size){
            let that = this;
            that.$http.forumResumeIntoEnterprisePostList({
                userId: parseInt(that.$store.state.userId),
                page,
                size
            }).then(res => {
                if (res.code == 200) {
                    console.log(res.data);
                    let { records } = res.data
                    that.resumeList = records;
                    that.currentPage = res.data.current;//当前页数
                    that.total = res.data.total
                }
            })
        },
    },
}

</script>
<style lang='less' scoped>
.BusinessManagementIndex {
    .head {
        padding: 20px 0;
        border-bottom: 1px solid #F7F7F8;

        .LeftPart {
            width: 55px;
            cursor: pointer;

            .logo {
                width: 53px;
                height: 53px;
                margin-right: 18px;
            }

            .shiliaologo {
                width: 77px;
                height: 38px;
            }

        }

        .el-breadcrumb ::v-deep .el-breadcrumb__inner {
            color: #666666 !important;
            font-weight: 400 !important;
            font-size: 14px;
        }

        .el-breadcrumb__item:last-child ::v-deep .el-breadcrumb__inner {
            color: #0363FA !important;
            font-weight: 400 !important;
            font-size: 14px;
        }
    }

    .main {
        margin-top: 30px;

        .title {
            width: 100%;

            .select {
                color: #0363FA;
                font-size: 24px;
                font-family: HarmonyOS Sans SC;
                font-weight: 500;
                border-bottom: 2px solid #0363FA;
                padding-bottom: 26px;
                cursor: pointer;
            }

            .Noselect {
                font-size: 24px;
                font-family: HarmonyOS Sans SC;
                font-weight: 500;
                cursor: pointer;
            }
        }
        .btn {
            margin-top: 10px;
            color: #FFFFFF;
            font-size: 16px;
            height: 60px;
            width: 160px;
            line-height: 60px;
            text-align: center;
            background: linear-gradient(90deg, #0873F4 0%, #39A8F9 100%);
            border-radius: 10px;
            cursor: pointer;
        }
        .jodList {
            width: 100%;
            margin-top: 50px;
            .jodItem {
                margin-bottom: 30px;
                border-radius: 20px;
                // border: 1px solid #C4C4C4;
                background: #F8F8F8;
                width: 700px;
                height: 96px;
                padding: 40px 70px;
                cursor: pointer;
                .position{
                    font-weight: 400;
                    font-size: 24px;
                    color: #333333;
                    width: 250px;
                }
                .resumeCount {
                    padding: 0 10px;
                    height: 30px;
                    line-height: 30px;
                    text-align: center;
                    background: #7DB3F6;
                    font-weight: 400;
                    font-size: 14px;
                    color: #FFFFFF;
                    border-radius: 20px;
                }
                .salary{
                    font-weight: 500;
                    font-size: 18px;
                    color: #333;
                }
                .createDate{
                    color: #999;
                    font-size: 16px;
                    font-weight: 400;
                }
                .enterpriseName{
                    margin-left: 10px;
                    font-weight: 400;
                    font-size: 16px;
                    color: #333333;
                }
            }
        }
    }
}
</style>
  